import React, { Component } from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route, HashRouter } from 'react-router-dom';
import Impressum from './components/pages/Impressum';
import Datenschutz from './components/pages/Datenschutz';
import Kontakt from './components/pages/Kontakt';
import Apps from './components/pages/Apps';
import Laufdatenuebersicht from './components/pages/Laufdatenuebersicht';
import NotFound from './components/pages/NotFound';
import Footer from './components/Footer'
import EmailKontakt from './components/EmailKontakt';
import AddLaufData from './components/pages/AddLaufData';



class App extends Component {
  render() {

    return (
      <>
        <HashRouter basename={'/'}>
          <Navbar />
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/impressum' component={Impressum} />
            <Route path='/datenschutz' component={Datenschutz} />
            <Route path='/kontakt' component={Kontakt} />
            <Route path="/apps" component={Apps} />
            <Route path="/laufdaten" component={Laufdatenuebersicht} />
            <Route path="/addlaufdata" component={AddLaufData} />
            <Route path="/404" component={NotFound} />
            <Route component={NotFound} />
          </Switch>
          <Footer />
        </HashRouter >
      </>
    );
  }
}

export default App;