import React, { Component } from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { TextField, Button, FormControl, FormControlLabel, FormHelperText, Checkbox, MenuItem } from '@material-ui/core';
import axios from 'axios';
import Swal from 'sweetalert2'
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import TimeField from 'react-simple-timefield';


const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: '#56B52A',
            dark: '#00B500',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
        },
    },
});


function fuehrendeNull(pWert){
    if(pWert < 10)
    {
        pWert = "0"+pWert;
    }
    return pWert;
}

const obj = require('./Punktesystem.json');
 
function betweenTime(pUntergrenze, pObergrenze, pWert){
    try{
    var untergrenze = pUntergrenze.split(':');
    var obergrenze = pObergrenze.split(':');
    var wert = pWert.split(':');
    console.log(wert[0]+", "+wert[1]+", "+wert[2])

    if(wert[0] >= untergrenze[0] & wert[0] <= obergrenze[0])
    {
        if(wert[1] >= untergrenze[1] & wert[1] <= obergrenze[1])
        {
            if(wert[2] >= untergrenze[2] & wert[2] <= obergrenze[2])
            {
                return 1;
            }
            else{
                return 0;
            }
        }
        else{
            return 0;
        }
    }
    else{
        return 0;
    }
    }
    catch(error){

    }

}

class AddLaufData extends React.Component {
    constructor(props) {
        super(props);
        this.addFormData = this.addFormData.bind(this);
        this.state = {
            data: [],
            punktesystem: [],
            strecke: 1,
            zeit: new Date(null),
            pace: 0, 
            punkte:0
        }
    }
    componentDidMount() {
        //get request
        axios.get('https://www.svb-lauftreff.betz-group.de/Backend/getPerson.php').then(res => {

            this.setState({ data: res.data });
        });

    }

    //Form Submission
    addFormData(evt) {
        evt.preventDefault();
        const fd = new FormData();
        //fd.append('test', document.getElementById('test').value);
        fd.append('lauefer', document.getElementById('lauefer').value);
        fd.append('punkte', document.getElementById('punkte').value);
        fd.append('strecke', document.getElementById('strecke').value);
        fd.append('gesamtzeit', document.getElementById('gesamtzeit').value);
        fd.append('pace', document.getElementById('pace').value);

        axios.post('https://www.svb-lauftreff.betz-group.de/Backend/sendLaufData.php', fd
        ).then(res => {
            //Success alert
            Swal.fire({
                title: 'Laufdaten',
                text: res.data.data,
                type: 'success',

            });
            this.myFormRef.reset();
        }
        );
    }

    setZeitState(event) {
        var field = event.target.name;
        var value = event.target.value;
        this.state.zeit = value;
        this.setState({ field: this.state.zeit });
        this.setPaceState();
        
    }

    setStreckeState(event) {
        var field = event.target.name;
        var value = event.target.value;
        this.state.strecke = value;
        this.setState({ field: this.state.strecke });

    }

    setPaceState(event) {       
        var seconds;
        var param = this.state.zeit.split(':')
        var seconds = Number(param[0]) * 3600 + Number(param[1]) * 60 + Number(param[2]);
        var nPace = (seconds / this.state.strecke);
        var paceminutes = parseInt(nPace / 60);
        var paceseconds = nPace % 60;

        //this.state.pace = seconds;
        var paceText = "00:" + fuehrendeNull(paceminutes) + ":" + fuehrendeNull(parseInt(paceseconds));
        this.setState({ pace:  paceText});
        this.setPunkte(paceText);
        /*var field = event.target.name;
        var value = event.target.value;
        this.state.pace = value;
        this.setState({ field: this.state.pace });
        this.setPunkte();*/
    }

    setPunkte(pPaceText){
        for (var i = 0; i < obj.length; i++) {
            if(betweenTime(obj[i].untergrenze,obj[i].obergrenze, pPaceText)==1){

                switch (true) {
                    case (parseInt(this.state.strecke)==6): this.setState({punkte: obj[i].punkte *1.1}); break;
                    case (parseInt(this.state.strecke)==7): this.setState({punkte: obj[i].punkte *1.2}); break;
                    case (parseInt(this.state.strecke)==8): this.setState({punkte: obj[i].punkte *1.3}); break;
                    case (parseInt(this.state.strecke)==9): this.setState({punkte: obj[i].punkte *1.4}); break;
                    case (parseInt(this.state.strecke)>=10): this.setState({punkte: obj[i].punkte *1.5}); break;
                    default: this.setState({punkte: obj[i].punkte}); break;
                  }


                //this.setState({punkte: obj[i].punkte});
                break;
            }
            
        }     
    }




    render() {
        return (
            <>
                <div className="Daten">
                    <div className="kontakt-header">
                        <h1>Daten eingeben</h1>
                    </div>
                    <form ref={(el) => this.myFormRef = el} onSubmit={this.addFormData} >
                        <Grid
                            container
                            direction="column"
                            justify="stretch"
                            alignItems="center"
                            className="kontakt-form"
                            multiple
                        >
                            <select
                                id="lauefer"
                                required
                            >
                                {this.state.data.map((result) => {
                                    return (
                                        <option
                                            value={result.id}
                                        >
                                            { result.name}, { result.vorname}
                                        </option>
                                    );
                                })}
                            </select>


                            <TextField
                                className="textfield"
                                label="Strecke (in km)"
                                id="strecke"
                                variant="filled"
                                onChange={this.setStreckeState.bind(this)}
                                required
/>
                            <TextField
                                className="textfield"
                                label="Gesamtzeit (in HH:MM:SS)"
                                id="gesamtzeit"
                                variant="filled"
                                onChange={this.setZeitState.bind(this)}
                                required
                            />
                            <TextField
                                className="textfield"
                                label="Pace (in HH:MM:SS)"
                                id="pace"
                                variant="filled"
                                value={this.state.pace}
                                //onChange={this.setPaceState.bind(this)}
                                required
                                disabled
                            />
                            <TextField
                                className="textfield"
                                label="Punkte"
                                id="punkte"
                                variant="filled"
                                value={this.state.punkte}
                                required
                            />

                            <Button className="button" variant="contained" color="primary" type="submit" >Senden</Button>

                        </Grid>
                    </form>
                </div >
            </>
        );
    }
}

export default AddLaufData;




