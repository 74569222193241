import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import axios from 'axios';

import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

const headCells = [
    { id: 'name', numeric: false, disablePadding: false, label: '' },
    { id: 'punkte', numeric: true, disablePadding: false, label: 'Punkte' },
    { id: 'strecke', numeric: true, disablePadding: false, label: 'Strecke (in km)' },
    { id: 'gesamtzeit', numeric: true, disablePadding: false, label: 'Gesamtzeit (HH:MM:SS)' },
    { id: 'pace', numeric: true, disablePadding: false, label: 'Pace (HH:MM:SS)' },
    { id: 'anzahlLauf', numeric: true, disablePadding: false, label: 'Anzahl Läufe' },
    { id: 'kmprolauf', numeric: true, disablePadding: false, label: 'km/ Lauf' },
];




function createData(name, strecke, gesamtzeit, pace, anzahlLauf, kmprolauf) {
    return {
        name, strecke, gesamtzeit, pace, anzahlLauf, kmprolauf, history: [
            { Name: 'Betz, Florian', Strecke: '21,1', Gesamtzeit: '1:35:02', Pace: '4:30', AnzahlLauf: '2', Kmprolauf: '15:30' },
            { Name: 'Betz, Peter', Strecke: '11,1', Gesamtzeit: '1:00:02', Pace: '5:30', AnzahlLauf: '3', Kmprolauf: '5:30' },
        ],
    };
}
var rows = [
    createData('Gruppe A', '21,1', '1:35:02', '4:30', '2', '15:30'),
    createData('Gruppe B', '11,1', '0:35:02', '4:45', '1', '11,1'),
];
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell />
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >{headCell.label}
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >

                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
        <Toolbar
            className={classes.root}
        >

            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">

                Top 10 Läufer
                </Typography>


            <Tooltip title="Filter list">
                <IconButton aria-label="filter list">
                    <FilterListIcon />
                </IconButton>
            </Tooltip>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell colSpan={1}>

                </TableCell>
                <TableCell component="th" scope="row" align="left">
                    Gesamtdaten
                </TableCell>
                <TableCell align="left">{row.Punkte}</TableCell>
                <TableCell align="left">{row.Gesamtstrecke}</TableCell>
                <TableCell align="left">
                    {row.Gesamtzeit}
                </TableCell>
                <TableCell align="left">{row.Durchschnittspace}</TableCell>
                <TableCell align="left">{row.anzahlLauf}</TableCell>
                <TableCell align="left">{(row.Gesamtstrecke / row.anzahlLauf).toFixed(2)}</TableCell>
            </TableRow>

        </React.Fragment >
    );
}




export default function Gesamtdaten() {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('strecke');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);



    const [data, setData] = React.useState([]);

    useEffect(async () => {
        const result = await axios(
            'https://www.svb-lauftreff.betz-group.de/Backend/gesamtData.php',
        );
        setData(result.data);
    });


    return (
        <div>
            <div className={classes.root} >
                <Paper className={classes.paper}>
                    {/*<EnhancedTableToolbar />*/}
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <EnhancedTableHead
                                classes={classes}

                                rowCount={rows.length}
                            />

                            <TableBody>


                                {stableSort(data, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        //const isItemSelected = isSelected(row.name);
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return (
                                            <Row key={row.name} row={row} />
                                        );
                                    })}

                            </TableBody>
                        </Table>
                    </TableContainer>

                </Paper>

            </div>
        </div>
    );
}