import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
	table: {
		//minWidth: 650,
	},
});

function createPaceSector(pace, points) {
	return { pace, points };
}

const pacesector = [
	createPaceSector('00:00:01 - 00:03:59', 10),
	createPaceSector('00:04:00 - 00:04:19', 9),
	createPaceSector('00:04:20 - 00:04:39', 8),
	createPaceSector('00:04:40 - 00:04:59', 7),
	createPaceSector('00:05:00 - 00:05:19', 6),
	createPaceSector('00:05:20 - 00:05:39', 5),
	createPaceSector('00:05:40 - 00:05:59', 4),
	createPaceSector('00:06:00 - 00:06:19', 3),
	createPaceSector('00:06:20 - 00:06:39', 2),
	createPaceSector('00:06:40 - 00:06:59', 1),
	createPaceSector('00:07:00 - 00:10:00', 0),
];

function createKmFaktor(pace, points) {
	return { pace, points };
}
const kmFaktor = [
	createKmFaktor('> 10 km', 1.5),
	createKmFaktor('9-10 km', 1.4),
	createKmFaktor('8-9 km', 1.3),
	createKmFaktor('7-8 km', 1.2),
	createKmFaktor('6-7 km', 1.1),
	createKmFaktor('5-6 km', 1.0),
];

export default function DenseTable() {
	const classes = useStyles();

	return (
		<div>
			<div>
				<TableContainer component={Paper}>
					<Table className={classes.table} size="small" aria-label="a dense table">
						<TableHead>
							<TableRow>
								<TableCell>Pace (HH:MM:SS)</TableCell>
								<TableCell align="right">Punkte</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{pacesector.map((row) => (
								<TableRow key={row.pace}>
									<TableCell component="th" scope="row">
										{row.pace}
									</TableCell>
									<TableCell align="right">{row.points}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
			<Typography>
				Die Punktzahl, die oben mit der Pace zustande kommt wird nun mit folgendem Faktor multipliziert.
				Das heißt, umso schneller und länger, desto mehr Punkte.
			</Typography>
			<div>
				<TableContainer component={Paper}>
					<Table className={classes.table} size="small" aria-label="a dense table">
						<TableHead>
							<TableRow>
								<TableCell>Strecke</TableCell>
								<TableCell align="right">Faktor</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{kmFaktor.map((row) => (
								<TableRow key={row.pace}>
									<TableCell component="th" scope="row">
										{row.pace}
									</TableCell>
									<TableCell align="right">{row.points}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
			<Typography>
				<u>Beispiel:</u> Strecke = 8.5 km; Pace = 00:04:50 <br></br>
				Gesamtpunktzahl = 7 * 1,3 = 9.1 Punkte
			</Typography>
		</div>
	);
}