import React, { Component } from 'react';
import '../../App.css';
import CollapsibleTable from '../collapse_table';
import GroupInfo from '../Group_Info';
import HeroSection from '../HeroSection';
import Imagetext from '../Imagetext';
import { createMuiTheme, TableCell, TableContainer, ThemeProvider } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import { Icon, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Beautifulimage from '../Beatifulimage';
import Regelwerk from './Regelwerk';



function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}


const images = [
  {
    original: 'images/lauefer.jpg',
  },
  {
    original: 'images/lauefer2.jpg',
  },
  {
    original: 'images/lauefer3.jpg',
  },
  {
    original: "images/laufchallenge_landing_page_Ipad.png"
  }
];

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#56B52A',
      dark: '#00B500',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

class Home extends Component {

  render() {
    return (
      <>
        <HeroSection
          title="SV Baustetten"
          subtitle="Laufdaten"
          imgpath="images/fussballfeld.jpg"
        />
        <div className="content">
          <Imagetext
            title="Laufchallenge"
            images={images}
            subtitle="Joggen | Intervall | Wettkampf"
            interval="5000">

            <p>
              Jeder läuft für sich und nimmt seinen Lauf mit einer App oder Uhr auf.
              Danach bitte ein Bild an Flo Betz oder an mich senden.
              Darauf muss die zurückgelegte Strecke und die Dauer ersichtlich sein.
              Wir werden die Daten dann in der von Flo extra dafür entworfenen Seite einpflegen.
                    </p>

            <p>
              Uns Trainer geht es vor allem darum, dass jeder für sich was macht und fit wird.
              Bei den Läufen kann man durchaus auch Intervalle einbauen (z.B: km schneller, dann km langsamer).
            </p>
            <ThemeProvider theme={theme}>
              <Link to='/laufdaten' className="nextcloud-link">
                <Button
                  variant="contained"
                  color="default"
                  startIcon={<CloudUpload />}
                  variant="contained"
                  color="primary"
                  type="submit"

                >
                  Laufdaten
                        </Button>
              </Link>
            </ThemeProvider>
          </Imagetext>
          <GroupInfo />
          <Beautifulimage subtitle="Regelwerk"/>
          <Imagetext
                    title="Regelwerk"
                    //subtitle="SVB | Laufa lau | Vesper"
                    bgcolor="#cccccc"
                    interval="10000"
                    images={[
                        {
                            original: 'images/betz-group_landingpage_ipad.png'
                        },
                    ]}>
                    <p>Abgeändertes Regelwerk für die Laufchallenge 2.0!</p>
                    <ol>
                        <li>Strecke muss mindestens 5km lang sein</li>
                        <li>Bild oder Screenshot auf dem die gelaufene Strecke und die Dauer zu sehen ist, zu Marci S. oder Flobe schicken</li> 
                        <li>
                          Laufdatum und Absendedatum müssen identisch sein, 
                          es dürfen keine Läufe aufgehoben und am Ende der Laufchallenge versendet werden
                        </li>          
                    </ol>
                    <p>Die entsprechende Pace wird dann in Sektoren eingeteilt - je schneller desto mehr Punkte gibt es. Die Einteilung sieht folgendermaßen aus:</p>
                    <Regelwerk/>
                </Imagetext>
        </div>
      </>
    );
  }
}

export default Home;