import React, { Component } from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import Imagetext from '../Imagetext';
import TextContainer from '../TextContainer';

class Apps extends Component {

    render() {

        return <div>

            <HeroSection
                title="Apps"
                imgpath="images/Frau-mit-Smartphone.jpg"
            />
            <div className="content">

                {/*<TextContainer
                    title="SVB-Laufchallenge-App als Android App"
                >

                    <h2>Sie wollen die SVB-Laufchallenge-App als Android Smartphone App installieren? </h2>
                    <p>Mit diesen Schritten können Sie die App installieren:</p>
                    <ol>
                        <li>Öffnen sie den Google Chrome Browser</li>
                        <li>Rufen sie die SVB-Laufchallenge-App Seite auf</li>
                        <li>Öffnen Sie die Liste oben rechts</li>
                        <li>Wählen sie <strong>App installieren</strong> aus</li>
                        <li>Folgen Sie den Anweisungen</li>
                    </ol>

                </TextContainer>
                <TextContainer title="SVB-Laufchallenge-App als IOS App">
                    <h2>Sie wollen die SVB-Laufchallenge-App als IOS Smartphone App installieren? </h2>
                    <p>Mit diesen Schritten können Sie die App installieren:</p>
                    <ol>
                        <li>Öffnen sie den Saferi Browser</li>
                        <li>Rufen sie die SVB-Laufchallenge-App Seite auf</li>
                        <li>Wahlen Sie das Symbol <i class="fas fa-external-link-alt"></i> aus</li>
                        <li>Wählen sie <strong>Zum Home-Bildschirm hinzufügen</strong> aus</li>
                        <li>Bestätigen Sie durch Hinzufügen den Vorgang</li>
                        <li>Die App wurde nun als App auf ihrem Smartphone installiert</li>
                    </ol>
                </TextContainer>
                <TextContainer title="SVB-Laufchallenge-App als Computer Applikation">
                    <h2>Sie wollen die SVB-Laufchallenge-App als Applikation auf ihrem Computer installieren?</h2>
                    <p>Mit diesen Schritten können Sie die Applikation installieren</p>
                    <ol>
                        <li>Öffnen sie den Google Chrome Browser</li>
                        <li>Rufen sie die SVB-Laufchallenge-App Seite auf</li>
                        <li>Öffnen Sie die Liste oben rechts</li>
                        <li>Wählen sie <strong>SVB-Lauftreff-App installieren</strong> aus</li>
                        <li>Folgen Sie den Anweisungen</li>
                    </ol>
                    <p>Viel Spaß beim ausprobieren!</p>
                </TextContainer>*/}
                <Imagetext
                    title="SVB-Laufchallenge-App"
                    subtitle="Web | App | Responsive"
                    bgcolor="#cccccc"
                    interval="10000"
                    images={[
                        {
                            original: 'images/betz-group_landingpage_mobile_smartphone.png',
                        },
                        {
                            original: 'images/betz-group_landingpage_ipad.png'
                        },
                    ]}>
                    <p>Mit diesen Schritten können Sie die Applikation auf ihrem Smartphone installieren</p>
                    <p>Android:</p>
                    <ol>
                        <li>Öffnen sie den Google Chrome Browser</li>
                        <li>Rufen sie die SVB-Laufchallenge-App Seite auf</li>
                        <li>Öffnen Sie die Liste oben rechts</li>
                        <li>Wählen sie <strong>SVB-Laufchallenge-App installieren</strong> aus</li>
                        <li>Folgen Sie den Anweisungen</li>
                    </ol>

                    <p>Iphone/ Ipad:</p>
                    <ol>
                        <li>Öffnen sie den Saferi Browser</li>
                        <li>Rufen sie die SVB-Laufchallenge-App Seite auf</li>
                        <li>Wahlen Sie das Symbol <i class="fas fa-external-link-alt"></i> aus</li>
                        <li>Wählen sie <strong>Zum Home-Bildschirm hinzufügen</strong> aus</li>
                        <li>Bestätigen Sie durch Hinzufügen den Vorgang</li>
                        <li>Die App wurde nun als App auf ihrem Smartphone installiert</li>
                    </ol>
                    <p>Viel Spaß beim ausprobieren!</p>
                </Imagetext>

                <Imagetext
                    title="SVB-Laufchallenge-App"
                    subtitle="Web | App | Responsive"
                    /*bgcolor="#cccccc"*/
                    interval="4000"
                    images={[
                        {
                            original: 'images/betz-group_landingpage_laptop.png',
                        },
                    ]}>
                    <p>Mit diesen Schritten können Sie die Applikation auf ihrem Computer installieren</p>
                    <ol>
                        <li>Öffnen sie den Google Chrome Browser</li>
                        <li>Rufen sie die SVB-Laufchallenge-App Seite auf</li>
                        <li>Öffnen Sie die Liste oben rechts</li>
                        <li>Wählen sie <strong>SVB-Laufchallenge-App installieren</strong> aus</li>
                        <li>Folgen Sie den Anweisungen</li>
                    </ol>


                    <p>Viel Spaß beim ausprobieren!</p>
                </Imagetext>
            </div>

        </div>;
    }
}
export default Apps;