import { Card, CardMedia, Grid, makeStyles } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import React from 'react';
import '../App.css';
//import Image from 'material-ui-image'
//import Countdown from 'react-countdown';
//import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { Component } from 'react';


const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));



//function CountdownPage({ title, subtitle, dark, id }) {
class CountdownPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        };
    }
    componentWillMount() {
        this.getTimeUntil(this.props.deadline);
    }
    componentDidMount() {
        setInterval(() => this.getTimeUntil(this.props.deadline), 1000);
    }
    leading0(num) {
        return num < 10 ? "0" + num : num;
    }

    getTimeUntil(deadline) {
        const time = Date.parse(deadline) - Date.parse(new Date());
        if (time < 0) {
            this.setState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        } else {
            const seconds = Math.floor((time / 1000) % 60);
            const minutes = Math.floor((time / 1000 / 60) % 60);
            const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
            const days = Math.floor(time / (1000 * 60 * 60 * 24));
            this.setState({ days, hours, minutes, seconds });
        }
    }

    render() {
        return (
            <>
                <div className={"content" + (this.props.dark ? "-dark" : "")} id={this.props.id}>
                    {/*<Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        className="countdown-container"
                        justify="space-evenly"
                    >*/}
                    <Grid
                        container
                        direction="row"
                        alignContent="center"
                        alignItems="center"
                        wrap="wrap"
                        spacing={1}
                        className="countdown-container"
                    >

                        <div className="countdown-counter">
                            <span className="countdown-counter-number">{this.leading0(this.state.days)}</span>
                            <span className="countdown-counter-text">Tagen</span>
                        </div>


                        <div className="countdown-counter">
                            <span className="countdown-counter-number">{this.leading0(this.state.hours)}</span>
                            <span className="countdown-counter-text">Stunden</span>
                        </div>
                        <div className="countdown-counter">
                            <span className="countdown-counter-number">{this.leading0(this.state.minutes)}</span>
                            <span className="countdown-counter-text">Minuten</span>
                        </div>
                        <div className="countdown-counter">
                            <span className="countdown-counter-number">{this.leading0(this.state.seconds)}</span>
                            <span className="countdown-counter-text">Sekunden</span>
                        </div>


                    </Grid>

                </div>

            </>
        );
    }
}

export default CountdownPage;


